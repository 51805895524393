.react-toast-notifications__container {
  z-index: 1200 !important;
}

.react-toast-notifications__toast__dismiss-button{
  opacity: 1 !important;
}

.react-toast-notifications__toast{
  &--error{
    background-color: lighten($red, 28%) !important;

    .react-toast-notifications__toast__icon-wrapper{
      background-color: $red;
    }

    .react-toast-notifications__toast__content{
      color: $red;
    }

    .react-toast-notifications__toast__dismiss-icon{
      fill: $red !important;
    }
  }

  &--success{
    background-color: lighten($green, 45%) !important;

    .react-toast-notifications__toast__icon-wrapper{
      background-color: $green;
    }

    .react-toast-notifications__toast__content{
      color: darken($green, 8%);
    }

    .react-toast-notifications__toast__dismiss-icon{
      fill: $green !important;
    }
  }
  
  &--warning{
    background-color: lighten($orange, 28%) !important;

    .react-toast-notifications__toast__icon-wrapper{
      background-color: $orange;
    }

    .react-toast-notifications__toast__content{
      color: darken($orange, 15%);;
    }

    .react-toast-notifications__toast__dismiss-icon{
      fill: $orange !important;
    }
  }

  &--info{

    .react-toast-notifications__toast__dismiss-icon{
      fill: $blue !important;
    }

  }
}
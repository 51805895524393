$font-family-monospace:   SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New","Noto Sans JP", monospace;
$font-family-sans-serif: "Roboto", "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
$font-family-base:        $font-family-sans-serif;

$body-color: #00101f;

$white:  #fff;
$black:  #00101f;

$gray-100:   #e9f1f7;
$gray-200:   #d1dce6;
$gray-300:   #c5cfd9;
$gray-400:   #bac3cc;
$gray-500:   #aeb7bf;
$gray-600:   #8b9399;
$gray-700:   #6e7780;
$gray-800:   #5e6973;
$gray-900:   #495159;

$theme-colors: (
  "primary":    #00bed3,
  "secondary":  #c5d7e6,
  "success":    #38cd5b,
  "info":       #3fa1f0,
  "warning":    #ffa75d,
  "danger":     #f86074,
  "light":      #e1edf7,
  "dark":       #5e6973
);

$primary:       #00bed3;
$secondary:     #c5d7e6;
$success:       #38cd5b;
$info:          #3fa1f0;
$warning:       #ffa75d;
$danger:        #f86074;
$light:         #e1edf7;
$dark:          #5e6973;

$blue:    #3fa1f0;
$indigo:  #3176a2;
$purple:  #8f68d6;
$pink:    #fd95ca;
$red:     #f86074;
$orange:  #ffa75d;
$yellow:  #ffc457;
$green:   #38cd5b;
$teal:    #42b28f;
$cyan:    #00bed3;

$link-color: darken(#00bed3, 5%);

$border-radius:               .5rem;
$border-radius-lg:            .6rem;
$border-radius-sm:            .4rem;

$box-shadow:  0 3px 8px rgba(0,0,0,0.175);

$sidebar-dark-minimizer-indicator-color: #FFFFFF;

$input-border-radius: $border-radius-sm;
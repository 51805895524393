@import 'variables.scss';
@import 'modal.scss';
@import 'toast.scss';

@import "~@coreui/coreui/scss/coreui.scss";
@import "fix-coreui.scss";

/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

  /* Override CoreUI */

.c-callout{
  border-radius: 0.25rem;
}

.c-sidebar{
  background-image: linear-gradient(-90deg, #{theme-color("primary")} 0%, #0f7ad1 100%);

  .c-sidebar-brand, .c-sidebar-minimizer{
    background-color: transparent;
  }

  .c-sidebar-minimizer:hover{
    background-color: theme-color("primary");
  }

  // メニューminimalizeモードの場合ロゴを非表示
  &.c-sidebar-minimized .c-sidebar-brand {
    visibility: hidden;
  }
}

.nav-pills{

  .nav-item{
    &:not(:last-child){
      margin-right: .5rem;
    }
  }

  .nav-link{
    transition: all 200ms;

    &:not(.active):hover{
      background-color: rgba($primary, 0.15);
    }
  }
}

.card-header{
  font-weight: 700;
}

.card-body.d-flex:last-child.p-0{
  & > div:first-child{
    border-radius: calc(.5rem - #{$border-width}) 0 0 calc(.5rem - #{$border-width});
  }

  & > div:last-child{
    border-radius: 0 calc(.5rem - #{$border-width}) calc(.5rem - #{$border-width}) 0;
  }
}

.dropdown-menu{
  box-shadow: $box-shadow;
}

.form-control{
  @include media-breakpoint-down(md){
    font-size: 16px;
    height: 38px;
  }
}

.text-value{
  font-size: 1.375rem;
}

.card .table{
  thead{
    background-color: $info;
    color: #fff;

    th{
      white-space: nowrap;
    }

  }
}

form .caret{
  float: right;

  &.caret-up{
    transform: rotate(180deg);
  }
}

/* Override React Date Picker */

.react-datepicker-popper {
  z-index: 2000;  // core-uiのヘッダーより下に表示されるのを回避
}

.react-datepicker-has-icon{
  position: relative;
  background-color: #fff;
  border-radius: $border-radius-sm;
  width: 100%;
}

.react-datepicker-icon{
  position: absolute;
  top: 0.375rem;
  right: 0.75rem;
}

.react-datepicker{
  box-shadow: $box-shadow;
  display: flex;

  button:focus{
    outline: none;
  }

  @media screen and (max-width: 320px){
    max-width: 280px;
    overflow-x: scroll;
  }
}

.react-datepicker, .react-datepicker__header, .react-datepicker__time-container{
  border-color: $gray-200;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
  border-bottom-color: $gray-200;
}

.react-datepicker-wrapper{
  position: relative;
  z-index: 2;
  width: 100%;

  .form-control:not(.is-invalid){
    background-color: transparent;
    padding-right: calc(1.5em + 0.75rem)
  }
}

.react-datepicker__month,
.react-datepicker__quarter {
  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 5%);
    }
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &--highlighted {
    background-color: $success;

    &:hover {
      background-color: darken($success, 5%);
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 5%);
    }
  }

  &--keyboard-selected {
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($primary, 0.5);
  }

  &:focus{
    outline-color: $primary;
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $primary;
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  li.react-datepicker__time-list-item {
    &--selected {
      background-color: $primary;
      &:hover {
        background-color: darken($primary, 5%);
      }
    }
  }
}

.select-form.is-invalid {
  > div {
    border-color: #f86074;
  }
}

.system-diagram {
  margin-bottom: 1rem;
  .close {
    top: 4px;
    right: 1.2rem;
    background-color: white;
    border-radius: 4px;
    padding: 0 4px;
    position: absolute;
    opacity: 1;
    .spinner-border {
      vertical-align: middle;
    }
    z-index: 100;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.tableHeader {
  background-color: #3fa1f0;
  color: white;
  font-weight: bold;
}

.column-fontsize-s[role="columnheader"] {
  font-size: 10px;
}
